@mixin buttonFooter {
  cursor: pointer;
}
@mixin itemFooter {
  text-align: center;
  width: 33.33%;
  margin: 10px auto;
  @include breakpoints(sm) {
    margin: 25px auto;
  }
}
@mixin textFooter {
  color: #ffffff;
  font-family: $font-quaternary;
  font-size: 16px;
  @include breakpoints(sm) {
    font-size: 30px;
  }
}

.footerBirthday {
  display: flex;
  justify-content: space-evenly;
  background-color: $color-tertiary;
  box-shadow: 0 0 20px $color-quaternary;
  &__map {
    @include itemFooter();
    p {
      @include textFooter();
    }
    a {
      @include buttonFooter();
    }
  }
  &__whatsapp {
    @include itemFooter();
    p {
      @include textFooter();
    }
    a {
      @include buttonFooter();
    }
  }
  &__calendar {
    @include itemFooter();
    p {
      @include textFooter();
    }
    a {
      @include buttonFooter();
    }
  }
}
