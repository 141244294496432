.countdownTimerCircle {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 150px;
  &__letter {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 10px;
    color: $color-primary;
    font-weight: bold;
    font-family: $font-secondary;
    @include breakpoints(sm) {
      font-size: 20px;
    }
    @include breakpoints(md) {
      font-size: 30px;
    }
  }
  &__number {
    background-color: $color-secondary;
    color: hsl(0, 0%, 100%);
    height: 75px;
    width: 75px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 35px;
    border-radius: 100%;
    font-family: $font-secondary;
    @include breakpoints(sm) {
      height: 100px;
      width: 100px;
      font-size: 45px;
    }
  }
}
