.countdownTimer {
  margin: 20% auto;
  @include breakpoints(sm) {
    margin: 12% 15%;
  }
  @include breakpoints(md) {
    margin: 7% 15%;
  }
  &__circles {
    display: flex;
    justify-content: space-evenly;
  }
  &__message {
    display: flex;
    justify-content: space-evenly;
    font-size: 24px;
    margin: 20px auto;
    color: $color-quaternary;
    font-family: $font-primary;
    font-weight: lighter;
    text-shadow: 0 0 30px $color-primary;
    @include breakpoints(sm) {
      margin: 20px auto;
      font-size: 30px;
    }
  }
}
