@mixin displayText {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.headerBirthday {
  height: 150px;
  padding-bottom: 70px;
  @include breakpoints(sm) {
    padding-bottom: 140px;
  }
  @include breakpoints(lg) {
    padding-bottom: 100px;
  }
  &__date {
    @include displayText();
    font-size: 35px;
    padding-top: 50px;
    font-family: $font-tertiary;
    color: $color-secondary;
    font-weight: bold;
    @include breakpoints(sm) {
      font-size: 50px;
    }
  }
  &__schedule {
    @include displayText();
    font-family: $font-tertiary;
    color: $color-secondary;
    font-size: 20px;
    font-weight: bold;
    @include breakpoints(sm) {
      font-size: 25px;
      padding-bottom: 10px;
    }
  }
  &__title {
    @include displayText();
    font-size: 40px;
    font-family: $font-primary;
    color: $color-primary;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 10px;
    animation: move linear 2000ms infinite;
    z-index: 2;
    @keyframes move {
      0% {
        text-shadow: 4px -4px 0 #da06c8, 3px -3px 0 #da06c8, 2px -2px 0 #da06c8,
          1px -1px 0 #da06c8, -4px 4px 0 #46f6ff, -3px 3px 0 #46f6ff,
          -2px 2px 0 #46f6ff, -1px 1px 0 #46f6ff;
      }
      25% {
        text-shadow: -4px -4px 0 #46f6ff, -3px -3px 0 #46f6ff,
          -2px -2px 0 #46f6ff, -1px -1px 0 #46f6ff, 4px 4px 0 #da06c8,
          3px 3px 0 #da06c8, 2px 2px 0 #da06c8, 1px 1px 0 #da06c8;
      }
      50% {
        text-shadow: -4px 4px 0 #da06c8, -3px 3px 0 #da06c8, -2px 2px 0 #da06c8,
          -1px 1px 0 #da06c8, 4px -4px 0 #46f6ff, 3px -3px 0 #46f6ff,
          2px -2px 0 #46f6ff, 1px -1px 0 #46f6ff;
      }
      75% {
        text-shadow: 4px 4px 0 #46f6ff, 3px 3px 0 #46f6ff, 2px 2px 0 #46f6ff,
          1px 1px 0 #46f6ff, -4px -4px 0 #da06c8, -3px -3px 0 #da06c8,
          -2px -2px 0 #da06c8, -1px -1px 0 #da06c8;
      }
      100% {
        text-shadow: 4px -4px 0 #da06c8, 3px -3px 0 #da06c8, 2px -2px 0 #da06c8,
          1px -1px 0 #da06c8, -4px 4px 0 #46f6ff, -3px 3px 0 #46f6ff,
          -2px 2px 0 #46f6ff, -1px 1px 0 #46f6ff;
      }
    }

    @include breakpoints(lg) {
      padding-top: 0px;
      font-size: 50px;
      padding-bottom: 0px;
    }
  }
  &__description {
    @include displayText();
    font-size: 25px;
    color: #ffffff;
    padding: 0 10px;
    font-family: $font-quaternary;
    text-align: center;
    text-shadow: 0 0 20px $color-quaternary;
    @include breakpoints(sm) {
      font-size: 40px;
    }
  }
}
