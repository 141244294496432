@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Silkscreen:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comforter+Brush&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bigelow+Rules&display=swap");
$font-primary: "Permanent Marker", cursive;
$font-secondary: "Silkscreen", cursive;
$font-tertiary: "Comforter Brush", cursive;
$font-quaternary: "Bigelow Rules", cursive;

$color-primary: #2d0249;
$color-secondary: #570a58;
$color-tertiary: #a9107a;
$color-quaternary: #f806cc;

@mixin breakpoints($point) {
  @if $point == sm {
    @media (min-width: 36em) {
      @content;
    }
  }
  @if $point == md {
    @media (min-width: 57.5em) {
      @content;
    }
  }
  @if $point == lg {
    @media (min-width: 80em) {
      @content;
    }
  }
}
