.background {
  position: absolute;
  z-index: -1000;
  min-width: 360px;
  max-width: 576px;
  @include breakpoints(sm) {
    width: 100%;
    max-width: 1440px;
  }

  img {
    width: 100%;
    height: 740px;
    object-fit: cover;
    opacity: 0.7;
    @include breakpoints(sm) {
      height: 800px;
    }
    @include breakpoints(md) {
      height: 880px;
    }
    @include breakpoints(lg) {
      height: 920px;
    }
  }
}
